import React, { useState, useEffect, useRef, useContext } from "react";
import "./game.css";
import AuthContext from "../homepage/AuthContext";

function Game() {
  const [currentImage, setCurrentImage] = useState(null);
  const [selectedResident, setSelectedResident] = useState(null);
  const [options, setOptions] = useState([]);
  const [score, setScore] = useState(0);
  const [buttonColors, setButtonColors] = useState([]);
  const [questionType, setQuestionType] = useState(null);
  const [residentsList, setResidentsList] = useState([]);
  const { userName } = useContext(AuthContext);
  const [isAnswering, setIsAnswering] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/residents.json");
      const data = await response.json();
      setResidentsList(data);
    };

    fetchData();
  }, []);

  function pickRandomImage(residentsList) {
    if (residentsList && residentsList.length > 0) {
      const randomIndex = Math.floor(Math.random() * residentsList.length);
      const randomResident = residentsList[randomIndex];

      return randomResident;
    }
    return null;
  }

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  async function checkAnswer(selectedOption, correctOption, index) {
    const correctIndex = options.findIndex((option) => option === correctOption);
    const newColors = options.map((_, i) => {
      if (i === index) {
        return selectedOption === correctOption ? "green" : "red";
      }
      return i === correctIndex && selectedOption !== correctOption ? "green" : "";
    });
  
    setButtonColors(newColors);
  
    if (isAnswering) { return }
    setIsAnswering(true);

    if (selectedOption === correctOption) {
      setScore((prevScore) => prevScore + 1);
    } else {
      setScore(0);
    }
  
    await new Promise((resolve) => setTimeout(resolve, 750));
    nextQuestion();
    setButtonColors([]);
  }  

  function generateOptions(selectedResident, residentsList, questionType) {
    let options = residentsList.filter((resident) => resident !== selectedResident);
  
    options = options.slice(0, 3);
    options.push(selectedResident);
    shuffleArray(options);
  
    if (questionType === "apartment") {
      setOptions(options.map((option) => option.apartment));
    } else {
      setOptions(options.map((option) => option.name));
    }
  }
  
  function nextQuestion() {
    const randomResident = pickRandomImage(residentsList);
  
    if (randomResident) {
      setCurrentImage(`${randomResident.name}, ${randomResident.apartment}`);
      setSelectedResident(randomResident);
      const newQuestionType = Math.random() < 0.5 ? "name" : "apartment";
      setQuestionType(newQuestionType);
      generateOptions(randomResident, residentsList, newQuestionType);
    } else {
      console.error("Error: Unable to pick a random resident.");
    }

    setIsAnswering(false);
  }  

  useEffect(() => {
    if (residentsList) {
      nextQuestion();
    }
  }, [residentsList]);

  return (
    <div className="game">
      <h1>{userName}'s Score: {score}</h1>
      {currentImage ? (
        <img src={`/residents/${currentImage}.jpg`} alt="Resident" />
      ) : (
        <div>No image found</div>
      )}
      {selectedResident && (
        <>
          <h2>
            {questionType === "name"
              ? `What is ${selectedResident.apartment}'s name?`
              : `Which is ${selectedResident.name}'s apartment?`}
          </h2>

          <div className="options-container">
            {options.length > 0 ? (
              options.map((option, index) => (
                <div
                  key={index}
                  className="option"
                  style={{ backgroundColor: buttonColors[index] }}
                  onClick={() => checkAnswer(option, questionType === "name" ? selectedResident.name : selectedResident.apartment, index)}
                  disabled={isAnswering}
                >
                  {option}
                </div>
              ))
            ) : (
              <div>No options found</div>
            )}
          </div>
        </>
      )}
    </div>
  );  
}

export default Game;
