// Homepage.js
import "./homepage.css";
import React, { useRef, useContext } from "react";
import AuthContext from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import AuthenticatedGame from "../game/authenticatedgame";

function Homepage() {
  const nameRef = useRef(null);
  const idRef = useRef(null);
  const { isAuthenticated, setIsAuthenticated, userName, setUserName } = useContext(AuthContext);
  const navigate = useNavigate();

  const readFile = async () => {
    const response = await fetch("/database.txt");
    const text = await response.text();
    const lines = text.split("\n");

    const inputName = nameRef.current.value;
    const inputId = idRef.current.value;

    const found = lines.some(line => {
      const [name, id] = line.split(" : ");
      return name.trim() === inputName && id.trim() === inputId;
    });

    if (found) {
      console.log("Verified");
      setIsAuthenticated(true);
      setUserName(inputName);
      navigate("/game");
    } else {
      console.log("Not found");
    }
  };

  return (
    <div className="Home">
      <header className="Home-header">
        <div className="circle"></div>
        &nbsp;

        <div className="field-container">
          <p>Authenticate User</p>
          <input ref={nameRef} className="name-field" type="text" placeholder="Enter Name" />
          &nbsp;
          <input ref={idRef} className="id-field" type="number" placeholder="Enter ID" />
          &nbsp;
          <button className="login" type="button" onClick={readFile}>Verify</button>
        </div>
      </header>
    </div>
  );
}

function App() {
  const { userName } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/game" element={<AuthenticatedGame userName={userName}/>} />
    </Routes>
  );
}

export default App;
