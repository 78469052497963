// AuthenticatedGame.js
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../homepage/AuthContext";
import Game from "./game";

function AuthenticatedGame() {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const { userName } = useContext(AuthContext);
  
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/", { replace: true });
    }
  }, [isAuthenticated, navigate]);

  if (!isAuthenticated) {
    return null;
  }

  return <Game userName={userName} />;
}

export default AuthenticatedGame;
