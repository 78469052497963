// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './homepage/AuthContext';
import Homepage from './homepage/homepage';
import './index.css';

ReactDOM.render(
    <BrowserRouter>
      <AuthProvider>
        <Homepage />
      </AuthProvider>
    </BrowserRouter>,
  document.getElementById('root')
);
